import { BASE_JWT_URL } from 'constants/environmentVariables';
import axiosInstance from 'services/axiosConfig';
import { addNewKeyToObject } from 'utils/helpers';
import { handleErrors } from 'actions/errors';
import {
	setAdvisoriesLoading,
	setAdvisoryObject,
	setWeather,
	setWeatherLoading
} from 'reducers/mapSlice';
import {
	accountRegionSelector,
	currentAccountIdSelector,
	featuresSelector
} from 'reducers/userAuthSlice';
import { setGlobalIsLoading } from 'reducers/globalSlice';
import { formatOperatingPictureIntoFeatures } from 'components/airSpace/airspaceUtils';
import {
	setOperatingPictureFeatures,
	setOperatingPicturePagination,
	addOperatingPictureLoadedPage,
	swapInNewFeatures
} from 'reducers/liveTrafficSlice';
import { setAirMeshChannels } from 'reducers/liveTrafficSlice';

// Add this at the top with other imports if needed
let operatingPictureAbortController = null;

//fetchAdvisory will get the different advisory of the selected location.
export const fetchAdvisory = (coords, id) => async (dispatch, getState) => {
	const accountRegion = accountRegionSelector(getState());
	const { airspace_v1 } = featuresSelector(getState());
	dispatch(setAdvisoriesLoading(true));
	const oldUrl = `${BASE_JWT_URL}/airspace/account/${id}/advisories?lat=${coords[1]}&lng=${coords[0]}`;
	const v1UrlItaly = `${BASE_JWT_URL}/v1/airspace/advisories?lat=${coords[1]}&lng=${coords[0]}`;
	const v1UrlAccountLevel = `${BASE_JWT_URL}/v1/account/${id}/airspace/advisories?lat=${coords[1]}&lng=${coords[0]}`;
	let url = oldUrl;
	if (accountRegion === 'italy') {
		url = v1UrlItaly;
	}
	if (airspace_v1) {
		url = v1UrlAccountLevel;
	}

	try {
		const response = await axiosInstance.get(url);
		const result = addNewKeyToObject(response.data.data, 'expanded', 'advisories', false);
		dispatch(setAdvisoryObject(result));
		dispatch(setAdvisoriesLoading(false));
		return response.data;
	} catch (error) {
		dispatch(setAdvisoriesLoading(false));
		handleErrors({ errors: error, dispatch });
	}
};

//fetch weather for location
export const fetchWeather = value => async dispatch => {
	dispatch(setWeatherLoading(true));
	const url = `${BASE_JWT_URL}/airspace/weather?lat=${value[1]}&lng=${value[0]}`;
	try {
		const response = await axiosInstance.get(url);
		dispatch(setWeatherLoading(false));
		dispatch(setWeather(response.data.data));
		return response;
	} catch {
		dispatch(setWeatherLoading(false));
		//Do not show red popup for weather error
	}
};

export const searchAirspace =
	({ searchQueryString, type }) =>
	async (dispatch, getState) => {
		const currentAccountId = currentAccountIdSelector(getState());
		const url = `${BASE_JWT_URL}/v1/account/${currentAccountId}/airspace/search?type=${type}&${searchQueryString}`;
		try {
			const response = await axiosInstance.get(url);
			return response.data.data;
		} catch (error) {
			handleErrors({ errors: error, dispatch });
		}
	};

export const fetchOperatingPicture = () => async (dispatch, getState) => {
	const currentAccountId = currentAccountIdSelector(getState());
	const { currentPage, hasMorePages, isLoading, isInitialLoad } =
		getState().liveTraffic.operatingPicturePagination;

	const PAGE_LENGTH = 50;

	// Cancel any existing request
	if (operatingPictureAbortController) {
		operatingPictureAbortController.abort();
	}
	// Create new abort controller for this request
	operatingPictureAbortController = new AbortController();

	// If this is the first page of a new refresh cycle, reset pagination
	if (currentPage === 1) {
		dispatch(
			setOperatingPicturePagination({
				currentPage: 1,
				totalPages: 1,
				loadedPages: [],
				isLoading: true,
				hasMorePages: true
			})
		);
	} else if (!isLoading) {
		dispatch(setOperatingPicturePagination({ isLoading: true }));
	}

	// Don't fetch if we're already loading or if we have no more pages
	if ((isLoading && currentPage !== 1) || !hasMorePages) {
		return;
	}

	try {
		const url = `${BASE_JWT_URL}/v1/account/${currentAccountId}/operating-pictures/resources?page=${currentPage}&page_length=${PAGE_LENGTH}`;
		const response = await axiosInstance.get(url, {
			signal: operatingPictureAbortController.signal
		});

		// Clear the controller after successful request
		operatingPictureAbortController = null;

		dispatch(setGlobalIsLoading(false));

		const data = response.data.data;
		const total = response.data.meta?.page?.total || 0;
		const operatingPictureFeatures = formatOperatingPictureIntoFeatures(data);

		// Update features
		dispatch(setOperatingPictureFeatures(operatingPictureFeatures));

		// Update pagination info with total from API
		dispatch(addOperatingPictureLoadedPage(currentPage));
		const totalPages = Math.ceil(total / PAGE_LENGTH);
		const nextPage = currentPage + 1;
		const hasMore = currentPage < totalPages;

		dispatch(
			setOperatingPicturePagination({
				currentPage: nextPage,
				totalPages,
				total,
				hasMorePages: hasMore,
				isLoading: false
			})
		);

		// Calculate if we should fetch next page
		const shouldFetchMore = total < 500 && nextPage <= totalPages;

		// If we have more pages and haven't hit our limit, fetch the next page
		if (shouldFetchMore) {
			dispatch(fetchOperatingPicture());
		} else {
			// If this was a refresh (not initial load) and we've loaded all pages, swap in the new features
			if (!isInitialLoad) {
				dispatch(swapInNewFeatures());
			}
		}

		return response.data.data;
	} catch (error) {
		// Don't show error message if request was cancelled
		if (error.name === 'CanceledError') {
			dispatch(setOperatingPicturePagination({ isLoading: false }));
			return;
		}
		dispatch(setOperatingPicturePagination({ isLoading: false }));
		handleErrors({ errors: error, dispatch });
	}
};

// Add this cleanup function to be called when panel closes
export const cancelOperatingPictureFetch = () => {
	if (operatingPictureAbortController) {
		operatingPictureAbortController.abort();
		operatingPictureAbortController = null;
	}
};

export const fetchAirMeshChannels = () => async (dispatch, getState) => {
	const currentAccountId = currentAccountIdSelector(getState());
	const url = `${BASE_JWT_URL}/v1/account/${currentAccountId}/airmesh-channels`;
	try {
		const response = await axiosInstance.get(url);
		dispatch(setAirMeshChannels(response.data.data.channels));
		return response.data.data.channels;
	} catch (error) {
		handleErrors({ errors: error, dispatch });
	}
};

export const fetchAirMeshToken = () => async (dispatch, getState) => {
	const currentAccountId = currentAccountIdSelector(getState());
	const url = `${BASE_JWT_URL}/v1/account/${currentAccountId}/airmesh-token`;
	try {
		const response = await axiosInstance.get(url);
		return response.data.data;
	} catch (error) {
		handleErrors({ errors: error, dispatch });
	}
};
