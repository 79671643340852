import { createSlice } from '@reduxjs/toolkit';
import { initialActivitiesState } from 'reducers/initialStates';

const activitiesSlice = createSlice({
	name: 'activities',
	initialState: {
		//Set by configureStore
	},
	reducers: {
		setActivitiesList(state, action) {
			state.activitiesList = action.payload;
		},
		setEditModeId(state, action) {
			state.editModeId = action.payload;
		},
		resetActivitiesSlice() {
			return initialActivitiesState;
		}
	}
});

export const { setActivitiesList, setEditModeId, resetActivitiesSlice } = activitiesSlice.actions;

export default activitiesSlice.reducer;

export const activitiesListSelector = state => state.activities.activitiesList;
export const editModeIdSelector = state => state.activities.editModeId;
