import dayjs from 'utils/customDayJS';
import * as _ from 'lodash-es';
import { t } from 'i18next';
import { convertCoordinatesFromDecimalToDMS } from 'components/airSpace/airspaceUtils';

export const dateDisplayBasic = 'L'; //MM/DD/YYYY
export const dateDisplayTimeMinutes = 'L LT z'; // 'MM/DD/YYYY hh:mm a z'; //Standard display of date, time and zone
export const dateDisplayTimeMinutesSeconds = 'L LTS z'; // 'MM/DD/YYYY hh:mm:ss a z';
export const timeDisplayHourMinutes = 'h:mm';
export const timeDisplay24Hours = 'HH:mm z'; // 'HH:mm z';
export const dateTimeDisplayTimeMinutes24Hours = 'L HH:mm z'; // 'MM/DD/YYYY HH:mm:ss z';

//Input formats - data comes into the app formatted this way and these are used to process it and reformat it
export const dateTimeInputFormat1 = 'H:mm:ss';
export const dateTimeInputFormat2 = 'DD-MMM-YYYY HH:mm';
export const dateTimeInputFormat3 = 'YYYY-MM-DDTHH:mm:ss.SSS';
export const dateTimeInputFormat4 = 'YYYYMMDDTHHmmss.SSS';
export const dateTimeInputFormat5 = 'YYYY-MM-DDTHH:mm:ss';

//Logic formats - used only in app logic - not for display
export const dateTimeLogicFormat1 = 'HH:mm';
export const dateTimeLogicFormat2 = 'YYYY-MM-DD HH:mm';

export const getTimezoneList = () => {
	return Intl.supportedValuesOf('timeZone');
};

export const formatTimezoneAbbrOffset = timezone => {
	const zoneOffset = getTimezoneOffset(timezone);
	return { name: timezone, label: `(${zoneOffset.abbr}${zoneOffset.offset}) ${timezone}` };
};

export const getTimezoneOffset = zone => {
	return {
		abbr: dayjs().tz(zone).format('z'),
		offset: dayjs().tz(zone).format('Z')
	};
};

export const convertSecsToHMS = (value, digits) => {
	function pad(number_) {
		return ('0' + number_).slice(-2);
	}

	let minutes = Math.floor(value / 60);
	value = value % 60;
	value = value.toFixed(0);
	let hours = Math.floor(minutes / 60);
	minutes = minutes % 60;
	return digits === 2
		? `${pad(hours)}h ${pad(minutes)}m ${pad(value)}s`
		: `${hours}h ${minutes}m ${value}s`;
};

export const convertSecondsToMinutes = (secs, place = 2) => {
	const minutes = secs / 60;
	return _.round(minutes, place);
};

export const convertMinutesToHoursMinutes = value => {
	const hours = Math.floor(value / 60).toLocaleString();
	const minutes = (value % 60).toFixed(0);
	return `${hours}${t('h')} ${minutes}${t('m')}`;
};

export const convertSecsToHMSobj = secs => {
	secs = Number(secs);
	const hours = Math.floor(secs / 3600);
	const mins = Math.floor((secs % 3600) / 60);
	return { hours, mins, secs };
};

export const convertToMomentObject = value => {
	return dayjs(value);
};

export const convertUnixToMomentObject = value => {
	return dayjs.unix(value);
};

export const convertToMomentFormat = value => {
	return dayjs.unix(value).utc().format(dateDisplayBasic);
};

export const getLocalTimezone = () => {
	return dayjs().tz(browserTimeZone).format('z');
};

export const differenceInDate = (date1, date2) => {
	// ex: date = [2007, 0, 29]
	var a = dayjs(date1, dateDisplayBasic);
	var b = dayjs(date2, dateDisplayBasic);
	return a.diff(b, 'days');
};

export const isActiveUnixDate = date => {
	return dayjs().unix() < date;
};

export const getTimeNow = () => {
	return dayjs();
};

export const unixToUTCConvertor = (dateTime, format = dateDisplayBasic) => {
	return dayjs.unix(dateTime).format(format);
};

export const addDurationToStartTime = (date, days, hours, minutes, seconds) => {
	return date.add(dayjs.duration({ days, hours, minutes, seconds }));
};

export const subtractDaysToDate = (utcDate, numberOfDays) => {
	return dayjs(utcDate).subtract(numberOfDays, 'days');
};

export const subtractYearsToDate = (utcDate, numberOfYears) => {
	return dayjs(utcDate).subtract(numberOfYears, 'years');
};

export const getTimeStampNow = () => {
	return dayjs().unix();
};

export const formatDate = (date, format = dateDisplayBasic, inputFormat) => {
	return dayjs(date, inputFormat).format(format);
};

// return differenc in minutes from start and end time.
// param are unix timestamp
export const diffInMinsUTC = (startTime, endTime) => {
	const end = dayjs(endTime * 1000);
	const start = dayjs(startTime * 1000);
	return dayjs.duration(end.diff(start)).asMinutes().toFixed(0);
};

// return total # of days
export const getDaysFromSeconds = seconds => {
	const days = dayjs.duration(seconds, 'seconds').asDays();
	return Math.trunc(days);
};

// return total # of hours
export const getHoursFromSeconds = (seconds, format = 'HH') => {
	return dayjs.utc(dayjs.duration(seconds, 'seconds').asMilliseconds()).format(format);
};

// return total # of minutes
export const getMinsFromSeconds = (seconds, format = 'mm') => {
	return dayjs.utc(dayjs.duration(seconds, 'seconds').asMilliseconds()).format(format);
};

export const getSecondsRemainingAfterMinutes = (seconds, format = 'ss') => {
	return dayjs.utc(dayjs.duration(seconds, 'seconds').asMilliseconds()).format(format);
};

export const tsToJsDateObject = ts => {
	if (!ts) return;
	return new Date(ts * 1000);
};

export const createHourMinutesBy5 = () => {
	let minutes = [];
	for (let index = 0; index <= 55; index += 5) {
		if (index <= 5) {
			minutes.push(`0${index}`);
		} else {
			minutes.push(index.toString());
		}
	}
	return minutes;
};

export const unixRoundDownToNearestMinute = unixTime => {
	const roundedTime = dayjs.unix(unixTime).startOf('minute');
	return roundedTime.unix();
};

export const getTimeZoneAbbreviation = timeZone => {
	return dayjs().tz(timeZone).format('z');
};

export const getTimeZoneAbbreviationOfDate = date => {
	return dayjs(date).tz().format('z');
};

export const getTimeZoneOffsetStringFromTime = time => {
	return dayjs(time).format('Z');
};

export const getTimeZoneOffsetString = timezone => {
	return dayjs().tz(timezone).format('Z');
};

export const convertStringToDateWithTimeZone = (
	timeString,
	timezone,
	inputFormat = dateTimeInputFormat2
) => {
	return dayjs(timeString, inputFormat).tz(timezone, true);
};

export const unixToStringWithTimeZone = (
	unixTimeStamp,
	timeZoneName,
	format = dateDisplayTimeMinutes
) => {
	if (!unixTimeStamp) return;
	const dayjsUtc = dayjs.unix(unixTimeStamp);
	let dayJSWithTimeZone;
	const tzNames = Intl.supportedValuesOf('timeZone');
	dayJSWithTimeZone =
		timeZoneName && tzNames.includes(timeZoneName)
			? dayjs.tz(dayjsUtc, timeZoneName).format(format)
			: dayjs.tz(dayjsUtc, browserTimeZone).format(format);
	return dayJSWithTimeZone;
};

export const unixToMomentObjectWithTimeZone = (unixTimeStamp, timeZoneName) => {
	if (!unixTimeStamp) return;
	const dayJSUTC = dayjs.unix(unixTimeStamp);
	let dayJSWithTimeZone;
	const tzNames = Intl.supportedValuesOf('timeZone');
	dayJSWithTimeZone =
		timeZoneName && tzNames.includes(timeZoneName)
			? dayjs.tz(dayJSUTC, timeZoneName)
			: dayjs.tz(dayJSUTC, browserTimeZone);
	return dayJSWithTimeZone;
};

export const convertLocalStringToStringWithTimeZone = (localString, timezone) => {
	if (!localString) return;
	let formattedWithTimeZone;
	formattedWithTimeZone = timezone
		? dayjs.unix(localString).tz(timezone).format(dateDisplayTimeMinutes)
		: dayjs.unix(localString).tz(browserTimeZone).format(dateDisplayTimeMinutes);
	return formattedWithTimeZone;
};

// timeZoneOffset is used to label the timepicker - example "(-7:00 Hours UTC/GMT)"
export const getTimeZoneOffsetLabel = date => {
	const offsetHours = dayjs(date).format('Z');
	return `(${offsetHours} ${t('Hours UTC/GMT')})`;
};

/**
 * ex output: "America/Los_Angeles"
 */
export const browserTimeZone = dayjs.tz.guess();

export const removeZoneOffset = zone => {
	const index = zone.indexOf(') ');
	return zone.slice(index + 2, zone.length);
};

export const convertHoursToSeconds = hours => {
	return hours * 60 * 60;
};

export const convertMinutesToSeconds = minutes => {
	return minutes * 60;
};

export const calculateUnixTimesDifferenceInMinutes = (time1, time2) => {
	const first = dayjs.unix(time1);
	const second = dayjs.unix(time2);
	const duration = dayjs.duration(second.diff(first)).asMinutes();
	return duration;
};

export const convertHMSStringToSeconds = hms => {
	const split = hms.split(' ');
	let hours = 0;
	let minutes = 0;
	let seconds = 0;
	if (split.length === 3) {
		hours = split[0].replace('h', '');
		minutes = split[1].replace('m', '');
		seconds = split[2].replace('s', '');
	}
	if (split.length === 2) {
		minutes = split[0].replace('m', '');
		seconds = split[1].replace('s', '');
	}
	if (split.length === 1) {
		seconds = split[0].replace('s', '');
	}
	return convertHoursToSeconds(hours) + convertMinutesToSeconds(minutes) + Number(seconds);
};

export const convertHiddenLevelDateToDayJS = (string, timezone) => {
	const date = dayjs.utc(string, dateTimeInputFormat4);
	const inTimezone = dayjs.tz(date, timezone);
	return inTimezone;
};

export const convertDroneTagDateToDayJS = (string, timezone) => {
	const date = dayjs.utc(string, dateTimeInputFormat5);
	const inTimezone = dayjs.tz(date, timezone);
	return inTimezone;
};

export const convertMissionAreaGeometryToString = geometry => {
	const coordinates = geometry.coordinates[0];
	return coordinates.map(pair => convertCoordinatesFromDecimalToDMS(pair)).join('; ');
};

export const timeFormatOptions = () => [
	{ value: 'h:mma', label: t('12 Hour') },
	{ value: 'HH:mm', label: t('24 Hour') }
];

export const dateFormatOptions = () => [
	{ value: 'MM/DD/YYYY', label: t('MM/DD/YYYY') },
	{ value: 'DD/MM/YYYY', label: t('DD/MM/YYYY') }
];
