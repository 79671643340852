import { createContext, useContext } from 'react';
import { useSelector } from 'react-redux';
import { unitsSelector } from 'reducers/globalSlice';

export const UnitContext = createContext();

export const UnitProvider = ({ children }) => {
	const units = useSelector(unitsSelector);

	return <UnitContext.Provider value={{ units }}>{children}</UnitContext.Provider>;
};

export const useUnitContext = () => {
	const context = useContext(UnitContext);
	if (!context) {
		throw new Error('useUnitContext must be used within a UnitProvider');
	}
	return context;
};
