import { createSelector, createSlice } from '@reduxjs/toolkit';
import { filterOperatingPicture, filterTrafficData } from 'components/airSpace/airspaceUtils';
import { mapBoundingBoxSelector } from 'reducers/mapSlice';
import { filterMapFeaturesByBoundingBox } from 'utils/mapUtils';
import { initialLiveTrafficState } from 'reducers/initialStates';

const liveTrafficSlice = createSlice({
	name: 'liveTraffic',
	initialState: {
		//Set by configureStore
	},
	reducers: {
		setAMTrafficFeatures(state, action) {
			//This is filtered out in dev tools so that is doesn't clutter the feed (see configureStore.js)
			state.airMeshFeatures = action.payload;
			// Check if any of the features are simulated
			state.hasSimulatedTraffic = action.payload.some(feature => feature.is_sim);
		},
		setAMWebSocketConnected(state, action) {
			state.airMeshWebsocketConnected = action.payload;
		},
		setAMConnectionError(state, action) {
			state.airMeshConnectionError = action.payload;
		},
		setAMConnectionLoading(state, action) {
			state.airMeshConnectionLoading = action.payload;
		},
		setAMPusherInstance(state, action) {
			state.airMeshPusherInstance = action.payload;
		},
		setPauseTrafficUpdates(state, action) {
			state.pauseTrafficUpdates = action.payload;
		},
		setAirMeshAircraftTypes(state, action) {
			state.airMeshAircraftTypes = action.payload;
		},
		setAirMeshProviderTypes(state, action) {
			state.airMeshProviderTypes = action.payload;
		},
		setAircraftTypesFilterValue(state, action) {
			state.aircraftTypesFilterValue = action.payload;
		},
		setProviderTypesFilterValue(state, action) {
			state.providerTypesFilterValue = action.payload;
		},
		setOwnshipIDs(state, action) {
			state.ownshipIDs = action.payload;
		},
		setOperatingPictureFeatures(state, action) {
			if (state.operatingPicturePagination.isInitialLoad) {
				// During initial load, append each page as it comes in
				state.operatingPictureFeatures = [...state.operatingPictureFeatures, ...action.payload];
			} else {
				// During refresh, collect features in temp array
				state.operatingPicturePagination.tempFeatures = [
					...state.operatingPicturePagination.tempFeatures,
					...action.payload
				];
			}
		},
		setOperatingPictureTypesFilterValue(state, action) {
			state.operatingPictureTypesFilterValue = action.payload;
		},
		setOperatingPictureSourcesFilterValue(state, action) {
			state.operatingPictureSourcesFilterValue = action.payload;
		},
		setAirMeshChannels(state, action) {
			state.airMeshChannels = action.payload;
		},
		setAirMeshSignalSourceTypes(state, action) {
			state.airMeshSignalSourceTypes = action.payload;
		},
		setSignalSourcesFilterValue(state, action) {
			state.signalSourcesFilterValue = action.payload;
		},
		setHideSimulatedTraffic(state, action) {
			state.hideSimulatedTraffic = action.payload;
		},
		resetAMState(state) {
			state.airMeshFeatures = [];
			state.airMeshWebsocketConnected = false;
			state.airMeshConnectionError = false;
			state.airMeshConnectionLoading = false;
			state.airMeshPusherInstance = null;
		},
		resetLiveTrafficSlice() {
			return initialLiveTrafficState;
		},
		setOperatingPicturePagination(state, action) {
			state.operatingPicturePagination = {
				...state.operatingPicturePagination,
				...action.payload,
				tempFeatures: state.operatingPicturePagination.tempFeatures
			};
		},
		addOperatingPictureLoadedPage(state, action) {
			if (!state.operatingPicturePagination.loadedPages.includes(action.payload)) {
				state.operatingPicturePagination.loadedPages.push(action.payload);
			}
		},
		resetOperatingPictureState(state, action) {
			const isClosingPanel = action?.payload?.isClosingPanel;

			if (isClosingPanel) {
				// When closing panel, clear everything
				state.operatingPictureFeatures = [];
				state.operatingPicturePagination = initialLiveTrafficState.operatingPicturePagination;
			} else {
				// During refresh cycles, keep features until we have new ones
				state.operatingPicturePagination = {
					...initialLiveTrafficState.operatingPicturePagination,
					isInitialLoad: false, // Set to false since this is a refresh
					tempFeatures: []
				};
			}
		},
		swapInNewFeatures(state) {
			state.operatingPictureFeatures = state.operatingPicturePagination.tempFeatures;
			state.operatingPicturePagination.tempFeatures = [];
		}
	}
});

export const {
	setAMTrafficFeatures,
	setAMWebSocketConnected,
	setAMConnectionError,
	setAMConnectionLoading,
	setAMPusherInstance,
	resetAMState,
	setPauseTrafficUpdates,
	setAirMeshAircraftTypes,
	setAirMeshProviderTypes,
	setAircraftTypesFilterValue,
	setProviderTypesFilterValue,
	setOwnshipIDs,
	setOperatingPictureFeatures,
	setOperatingPictureTypesFilterValue,
	setAirMeshChannels,
	setOperatingPictureSourcesFilterValue,
	resetLiveTrafficSlice,
	setSignalSourcesFilterValue,
	setHideSimulatedTraffic,
	setAirMeshSignalSourceTypes,
	setOperatingPicturePagination,
	addOperatingPictureLoadedPage,
	resetOperatingPictureState,
	swapInNewFeatures
} = liveTrafficSlice.actions;

export default liveTrafficSlice.reducer;

const airMeshFeaturesSelector = state => state.liveTraffic.airMeshFeatures;

export const aircraftTypesFilterValueSelector = state => state.liveTraffic.aircraftTypesFilterValue;
export const providerTypesFilterValueSelector = state => state.liveTraffic.providerTypesFilterValue;
export const signalSourcesFilterValueSelector = state => state.liveTraffic.signalSourcesFilterValue;

export const trafficFeaturesSelector = createSelector(
	[
		airMeshFeaturesSelector,
		aircraftTypesFilterValueSelector,
		providerTypesFilterValueSelector,
		signalSourcesFilterValueSelector,
		mapBoundingBoxSelector,
		state => state.liveTraffic.hideSimulatedTraffic
	],
	(
		airMeshFeatures,
		aircraftTypesFilterValue,
		providerTypesFilterValue,
		signalSourcesFilterValueSelector,
		mapBoundingBox,
		hideSimulatedTraffic
	) => {
		let filtered = filterTrafficData(
			airMeshFeatures,
			aircraftTypesFilterValue,
			providerTypesFilterValue,
			signalSourcesFilterValueSelector
		);

		if (hideSimulatedTraffic) {
			filtered = filtered.filter(feature => !feature.is_sim);
		}

		if (mapBoundingBox) {
			const filteredByMapBounds = filterMapFeaturesByBoundingBox({
				features: filtered,
				boundingBox: mapBoundingBox
			});
			return filteredByMapBounds;
		} else {
			return filtered;
		}
	}
);
export const airMeshWebsocketConnectedSelector = state =>
	state.liveTraffic.airMeshWebsocketConnected;
export const airMeshConnectionErrorSelector = state => state.liveTraffic.airMeshConnectionError;
export const airMeshConnectionLoadingSelector = state => state.liveTraffic.airMeshConnectionLoading;
export const airMeshPusherInstanceSelector = state => state.liveTraffic.airMeshPusherInstance;
export const pauseTrafficUpdatesSelector = state => state.liveTraffic.pauseTrafficUpdates;
export const airMeshAircraftTypesSelector = state => state.liveTraffic.airMeshAircraftTypes;
export const airMeshProviderTypesSelector = state => state.liveTraffic.airMeshProviderTypes;
export const airMeshSignalSourceTypesSelector = state => state.liveTraffic.airMeshSignalSourceTypes;
export const ownshipsIDsSelector = state => state.liveTraffic.ownshipIDs;
export const operatingPictureFeaturesSelector = state => state.liveTraffic.operatingPictureFeatures;
export const operatingPictureTypesFilterValueSelector = state =>
	state.liveTraffic.operatingPictureTypesFilterValue;
export const operatingPictureSourcesFilterValueSelector = state =>
	state.liveTraffic.operatingPictureSourcesFilterValue;

export const operatingPictureSelector = createSelector(
	[
		operatingPictureFeaturesSelector,
		operatingPictureTypesFilterValueSelector,
		operatingPictureSourcesFilterValueSelector,
		mapBoundingBoxSelector
	],
	(
		operatingPictureFeatures,
		operatingPictureTypesFilterValue,
		operatingPictureSourcesFilterValue,
		mapBoundingBox
	) => {
		const filtered = filterOperatingPicture(
			operatingPictureFeatures,
			operatingPictureTypesFilterValue,
			operatingPictureSourcesFilterValue
		);
		if (mapBoundingBox) {
			const filteredByMapBounds = filterMapFeaturesByBoundingBox({
				features: filtered,
				boundingBox: mapBoundingBox
			});
			return filteredByMapBounds;
		} else {
			return filtered;
		}
	}
);
export const airMeshChannelsSelector = state => state.liveTraffic.airMeshChannels;
export const trafficUnitsSelector = state => state.liveTraffic.units;

export const hideSimulatedTrafficSelector = state => state.liveTraffic.hideSimulatedTraffic;
export const hasSimulatedTrafficSelector = state => state.liveTraffic.hasSimulatedTraffic;

export const operatingPicturePaginationSelector = state =>
	state.liveTraffic.operatingPicturePagination;
