import { createSlice } from '@reduxjs/toolkit';
import { initialStreamingState } from 'reducers/initialStates';

const streamingSlice = createSlice({
	name: 'streaming',
	initialState: {
		//Set by configureStore
	},
	reducers: {
		setStreamsList(state, action) {
			state.streamsList = action.payload;
		},
		setStreamsInitiallyLoaded(state, action) {
			state.streamsInitiallyLoaded = action.payload;
		},
		setStreamsLoading(state, action) {
			state.streamsLoading = action.payload;
		},
		setStreamMicEnabled(state, action) {
			state.streamMicEnabled = action.payload;
		},
		setStreamingMapView(state, action) {
			state.mapView = action.payload;
		},
		setStreamingEqualView(state, action) {
			state.equalView = action.payload;
		},
		setStreamingDrawerOpen(state, action) {
			state.drawerOpen = action.payload;
		},
		setSelectedStreams(state, action) {
			state.selectedStreams = action.payload;
		},
		setShareTokenLoading(state, action) {
			state.shareTokenLoading = action.payload;
		},
		setStreamingMapBounds(state, action) {
			state.mapBounds = action.payload;
		},
		setMicChecked(state, action) {
			state.micChecked = action.payload;
		},
		setMicPermissionGranted(state, action) {
			state.micPermissionGranted = action.payload;
		},
		resetStreamingSlice() {
			return initialStreamingState;
		}
	}
});

export const {
	setStreamsList,
	setStreamsInitiallyLoaded,
	setStreamsLoading,
	setStreamingMapView,
	setStreamingEqualView,
	setStreamingDrawerOpen,
	setSelectedStreams,
	setStreamMicEnabled,
	setShareTokenLoading,
	setStreamingMapBounds,
	setMicPermissionGranted,
	setMicChecked,
	resetStreamingSlice
} = streamingSlice.actions;

export default streamingSlice.reducer;

export const streamsListSelector = state => state.streaming.streamsList;
export const streamsInitiallyLoadedSelector = state => state.streaming.streamsInitiallyLoaded;
export const streamsLoadingSelector = state => state.streaming.streamsLoading;
export const streamMicEnabledSelector = state => state.streaming.streamMicEnabled;
export const streamingMapViewSelector = state => state.streaming.mapView;
export const streamingEqualViewSelector = state => state.streaming.equalView;
export const streamingDrawerOpenSelector = state => state.streaming.drawerOpen;
export const selectedStreamsSelector = state => state.streaming.selectedStreams;
export const streamingShareTokenLoadingSelector = state => state.streaming.shareTokenLoading;
export const streamingMapBoundsSelector = state => state.streaming.mapBounds;
export const micPermissionGrantedSelector = state => state.streaming.micPermissionGranted;
export const micCheckedSelector = state => state.streaming.micChecked;
