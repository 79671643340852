import { createSlice } from '@reduxjs/toolkit';
import { initialReportsState } from 'reducers/initialStates';

const reportsSlice = createSlice({
	name: 'reports',
	initialState: {
		//Set by configureStore
	},
	reducers: {
		resetReportsSlice() {
			return initialReportsState;
		},
		setReportsLoading(state, action) {
			state.reportsLoading = action.payload;
		},
		setReportsTotals(state, action) {
			state.reportsTotals = action.payload;
		},
		setActiveReports(state, action) {
			return {
				...state,
				activeReports: { ...state.activeReports, ...action.payload }
			};
		},
		setVisualData(state, action) {
			state.visualData = action.payload;
		},
		setDashboardLoading(state, action) {
			state.dashboardLoading = action.payload;
		}
	}
});

export const {
	resetReportsSlice,
	setReportsLoading,
	setReportsTotals,
	setActiveReports,
	setVisualData,
	setDashboardLoading
} = reportsSlice.actions;

export default reportsSlice.reducer;

export const reportsSelector = state => state.reports;
export const reportsLoadingSelector = state => state.reports.reportsLoading;
export const activeReportsSelector = state => state.reports.activeReports;
export const reportsTotalsSelector = state => state.reports.reportsTotals;
export const reportsVisualDataSelector = state => state.reports.visualData;
export const dashboardLoadingSelector = state => state.reports.dashboardLoading;
