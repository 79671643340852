import dayjs from 'utils/customDayJS';
import { updatePreviousPositions } from 'components/airSpace/airspaceUtils';
import { formatAirmeshDataIntoFeatures } from 'components/airSpace/airspaceUtils';
import {
	setAirMeshAircraftTypes,
	setAirMeshProviderTypes,
	setAirMeshSignalSourceTypes,
	airMeshAircraftTypesSelector,
	airMeshProviderTypesSelector,
	airMeshSignalSourceTypesSelector,
	setAMTrafficFeatures,
	pauseTrafficUpdatesSelector
} from 'reducers/liveTrafficSlice';
// Constants for feature state management
export const HIDE_ASTERIX_THRESHOLD_SECONDS = 10;
export const EXPIRE_ASTERIX_THRESHOLD_MINUTES = 10;
export const EXPIRE_REGULAR_THRESHOLD_SECONDS = 30;
export const INACTIVITY_TIMEOUT_MS = 30000;

// Feature state types
export const FEATURE_STATES = {
	EXPIRED: 'EXPIRED',
	VISIBLE: 'VISIBLE',
	HIDDEN: 'HIDDEN'
};

// Map to store hidden features
const hiddenFeaturesMap = new Map();

// Helper function to determine feature state
export const getFeatureState = (feature, now) => {
	const featureDate = dayjs(feature.properties.timestamp);
	const isAsterix = feature.properties.aircraft_type === 'asterix';
	const timeSinceUpdate = now.diff(featureDate, 'seconds');

	// Check expiration first
	const isExpired =
		featureDate <=
		(isAsterix
			? now.subtract(EXPIRE_ASTERIX_THRESHOLD_MINUTES, 'minutes')
			: now.subtract(EXPIRE_REGULAR_THRESHOLD_SECONDS, 'seconds'));

	if (isExpired) return FEATURE_STATES.EXPIRED;
	if (!isAsterix) return FEATURE_STATES.VISIBLE;
	return timeSinceUpdate > HIDE_ASTERIX_THRESHOLD_SECONDS
		? FEATURE_STATES.HIDDEN
		: FEATURE_STATES.VISIBLE;
};

// Helper function to update feature with previous data
export const updateFeatureWithPrevious = (newFeature, previousFeature) => {
	const altitudeChange = newFeature.properties?.altitude
		? newFeature.properties.altitude - previousFeature.properties.altitude
		: 0;

	const previousPositions = updatePreviousPositions(previousFeature, newFeature);

	return {
		...newFeature,
		altitude_change: altitudeChange,
		properties: {
			...newFeature.properties,
			altitude_change: altitudeChange,
			previous_positions: previousPositions
		}
	};
};

// Helper function to update features
export const updateFeatures = (incomingFeatures, currentFeatures) => {
	const now = dayjs();
	const processedFeatures = new Map();

	// Create maps for faster lookups
	const incomingFeaturesMap = new Map(
		formatAirmeshDataIntoFeatures(incomingFeatures).map(feature => [feature.id, feature])
	);

	const currentFeaturesMap = new Map(
		currentFeatures
			.filter(feature => getFeatureState(feature, now) !== FEATURE_STATES.EXPIRED)
			.map(feature => [feature.id, feature])
	);

	// Clean up expired features from hiddenFeaturesMap
	for (const [id, feature] of hiddenFeaturesMap) {
		if (getFeatureState(feature, now) === FEATURE_STATES.EXPIRED) {
			hiddenFeaturesMap.delete(id);
		}
	}

	const allFeatureIds = new Set([
		...incomingFeaturesMap.keys(),
		...currentFeaturesMap.keys(),
		...hiddenFeaturesMap.keys()
	]);

	for (const id of allFeatureIds) {
		const incomingFeature = incomingFeaturesMap.get(id);
		const currentFeature = currentFeaturesMap.get(id);
		const hiddenFeature = hiddenFeaturesMap.get(id);

		// Skip if no feature data available
		if (!incomingFeature && !currentFeature && !hiddenFeature) continue;

		// Determine which feature data to use
		let featureToProcess = null;

		if (incomingFeature) {
			// If we have new data, update it with previous data if available
			const previousFeature = currentFeature || hiddenFeature;
			featureToProcess = previousFeature
				? updateFeatureWithPrevious(incomingFeature, previousFeature)
				: incomingFeature;
		} else {
			// No new data, use existing feature
			featureToProcess = currentFeature || hiddenFeature;
		}

		if (!featureToProcess) continue;

		const state = getFeatureState(featureToProcess, now);
		const isAsterix = featureToProcess.properties.aircraft_type === 'asterix';

		if (isAsterix) {
			switch (state) {
				case FEATURE_STATES.VISIBLE:
					processedFeatures.set(id, featureToProcess);
					hiddenFeaturesMap.delete(id);
					break;
				case FEATURE_STATES.HIDDEN:
					hiddenFeaturesMap.set(id, featureToProcess);
					// Don't add to processedFeatures when hidden
					break;
				case FEATURE_STATES.EXPIRED:
					// Do nothing - let it be filtered out
					break;
				default:
					break;
			}
		} else {
			// Non-asterix features are always visible unless expired
			if (state !== FEATURE_STATES.EXPIRED) {
				processedFeatures.set(id, featureToProcess);
			}
		}
	}

	return [...processedFeatures.values()];
};

// Helper function to get unique types from features
export const getUniqueTypes = (features, typeKey) => {
	const types = features.map(feature => feature[typeKey]).filter(s => s !== null);
	return [...new Set(types)];
};
export const setAircraftTypes = updatedFeatures => (dispatch, getState) => {
	const types = updatedFeatures.map(feature => feature.aircraft_type);
	const existingTypes = airMeshAircraftTypesSelector(getState());
	const uniqueTypes = [...new Set([...types, ...existingTypes])];
	dispatch(setAirMeshAircraftTypes(uniqueTypes));
};

export const setProviderTypes = updatedFeatures => (dispatch, getState) => {
	//if the type is "uas_sentry" then change it to "UAS Sentry"
	const types = updatedFeatures.map(feature =>
		feature.provider === 'uas_sentry' ? 'UAS Sentry' : feature.provider
	);
	const existingTypes = airMeshProviderTypesSelector(getState());
	const uniqueTypes = [...new Set([...types, ...existingTypes])];
	dispatch(setAirMeshProviderTypes(uniqueTypes));
};

export const setSignalSourceTypes = updatedFeatures => (dispatch, getState) => {
	const types = updatedFeatures.map(feature => feature.signal_source).filter(s => s !== null);
	const existingTypes = airMeshSignalSourceTypesSelector(getState());
	const uniqueTypes = [...new Set([...types, ...existingTypes])];
	dispatch(setAirMeshSignalSourceTypes(uniqueTypes));
};

let inactivityTimeout = null;

const resetInactivityTimer = dispatch => {
	// Clear any existing timeout
	if (inactivityTimeout) {
		clearTimeout(inactivityTimeout);
	}

	// Set a new timeout
	inactivityTimeout = setTimeout(() => {
		//Clear all features if there has been no update on any channel for 30 seconds
		//Handles the case where no events are received to trigger the regular pruning of old features
		dispatch(setAMTrafficFeatures([]));
	}, INACTIVITY_TIMEOUT_MS);
};

//This is what is called when a new event is received from the SSE or Pusher connection
export const processData = data => (dispatch, getState) => {
	resetInactivityTimer(dispatch);
	const pauseTrafficUpdates = pauseTrafficUpdatesSelector(getState());
	if (!pauseTrafficUpdates) {
		const updatedFeatures = updateFeatures(data, getState().liveTraffic.airMeshFeatures);
		dispatch(setAMTrafficFeatures(updatedFeatures));
		dispatch(setAircraftTypes(updatedFeatures));
		dispatch(setProviderTypes(updatedFeatures));
		dispatch(setSignalSourceTypes(updatedFeatures));
	}
};
