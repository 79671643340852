import { configureStore } from '@reduxjs/toolkit';
import rootReducer from 'reducers';
import { LSAvailable } from 'utils/localStorageHandler';
import { sentryReduxEnhancer } from 'services/sentry';
import { airMeshMiddleware } from 'services/airMeshMiddleware';
import {
	initialGlobalState,
	initialUserAuthState,
	initialMapState,
	initialActivitiesState,
	initialFileUploadState,
	initialFlightPathViewerState,
	initialLaancState,
	initialLiveTrafficState,
	initialManageTablesState,
	initialMissionState,
	initialReportsState,
	initialRolesState,
	initialStreamingState
} from 'reducers/initialStates';

export const initialStoreState = {
	userAuth: initialUserAuthState,
	global: initialGlobalState,
	map: initialMapState,
	activities: initialActivitiesState,
	fileUpload: initialFileUploadState,
	flightPathViewer: initialFlightPathViewerState,
	laanc: initialLaancState,
	liveTraffic: initialLiveTrafficState,
	manageTables: initialManageTablesState,
	mission: initialMissionState,
	reports: initialReportsState,
	roles: initialRolesState,
	streaming: initialStreamingState
};

export const setupStore = () => {
	const lsAvailable = LSAvailable(); //This app requires localStorage to be enabled and available. This is the first place in the app that localStorage is used.
	if (lsAvailable) {
		return configureStore({
			reducer: rootReducer,
			// Middleware setup to disable warnings. Ref: https://redux-toolkit.js.org/api/getDefaultMiddleware#api-reference
			middleware: getDefaultMiddleware => [
				airMeshMiddleware(),
				...getDefaultMiddleware({
					serializableCheck: false,
					immutableCheck: false
				})
			],
			devTools: {
				actionsDenylist: [
					'liveTraffic/setAMTrafficFeatures',
					'liveTraffic/setAirMeshAircraftTypes',
					'liveTraffic/setAirMeshProviderTypes',
					'liveTraffic/setAirMeshSignalSourceTypes'
				] //Filter out the websocket payloads for traffic
			},
			enhancers: getDefaultEnhancers => getDefaultEnhancers().concat(sentryReduxEnhancer),
			preloadedState: initialStoreState
		});
	}
};

export const reduxStore = setupStore();
