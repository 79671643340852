import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import duration from 'dayjs/plugin/duration';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import localeData from 'dayjs/plugin/localeData';
import { TIME_FORMAT, DATE_FORMAT } from 'constants/localStorageConstants';

dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);
dayjs.extend(duration);
dayjs.extend(customParseFormat);
dayjs.extend(quarterOfYear);
dayjs.extend(localeData);

// Override `dayjs().format()`
const originalFormat = dayjs.prototype.format;

dayjs.prototype.format = function (format) {
	const localeData = dayjs.localeData();
	const defaultDateFormat = localeData.longDateFormat('L'); // Default locale date format
	const defaultTimeFormat = localeData.longDateFormat('LT'); // Default locale time format
	const defaultLongTimeFormat = localeData.longDateFormat('LTS'); // Default long time format

	// Get user preferences (fallback to locale defaults)
	const userDateFormat = localStorage.getItem(DATE_FORMAT) || defaultDateFormat;
	let userTimeFormat = localStorage.getItem(TIME_FORMAT) || defaultTimeFormat;

	// Check if the user-selected format is 24-hour
	const is24HourFormat = userTimeFormat.includes('HH');

	if (is24HourFormat) {
		// Remove AM/PM markers (both uppercase `A` and lowercase `a`)
		userTimeFormat = userTimeFormat.replace(/A|a/g, '').trim();
	}

	if (!format) {
		return originalFormat.call(this, `${userDateFormat} ${userTimeFormat}`);
	}

	return originalFormat.call(
		this,
		format
			.replace(
				/\bLTS\b/,
				is24HourFormat ? defaultLongTimeFormat.replace(/A|a/g, '').trim() : defaultLongTimeFormat
			) // Remove AM/PM if 24-hour
			.replace(/\bLT\b/, userTimeFormat) // Replace LT with the correct format
			.replace(/\bL\b/, userDateFormat) // Replace L (user's date format)
	);
};

export { default } from 'dayjs';
