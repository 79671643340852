import { createSlice } from '@reduxjs/toolkit';
import { initialMissionState } from 'reducers/initialStates';

const missionSlice = createSlice({
	name: 'mission',
	initialState: {
		//Set by configureStore
	},
	reducers: {
		resetMissionSlice() {
			return initialMissionState;
		},
		setMissionData(state, action) {
			return { ...state, ...action.payload };
		},
		setSGIWaiverLoadingCount(state, action) {
			state.sgiWaiverLoadingCount = state.sgiWaiverLoadingCount + action.payload;
		}
	}
});

export const { resetMissionSlice, setMissionData, setSGIWaiverLoadingCount } = missionSlice.actions;

export default missionSlice.reducer;

export const sgiWaiverLoadingCountSelector = state => state.mission.sgiWaiverLoadingCount;
