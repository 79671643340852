import { t } from 'i18next';

export const accountSharingOptions = isFreeAccount => {
	let options = [
		{ value: 'anonymous', label: t('Anonymous') },
		{ value: 'full', label: t('Full') }
	];

	if (!isFreeAccount) {
		options.push({ value: 'none', label: t('None') });
	}

	return options;
};

export const defaultUserRecencyThresholdFlightHours = 1;
export const defaultUserRecencyThresholdDays = 30;

// Convert API format to frontend format
export const parseTimeFormatFromAPI = apiFormat => {
	if (apiFormat === '24 hour') {
		return 'HH:mm';
	} else if (apiFormat === '12 hour') {
		return 'h:mma';
	} else {
		return null;
	}
};

export const parseDateFormatFromAPI = apiFormat => {
	if (apiFormat === 'dd/mm/yyyy') {
		return 'DD/MM/YYYY';
	} else if (apiFormat === 'mm/dd/yyyy') {
		return 'MM/DD/YYYY';
	} else {
		return null;
	}
};

// Convert frontend format to API format
export const parseTimeFormatForAPI = frontendFormat => {
	return frontendFormat === 'HH:mm' ? '24 hour' : '12 hour';
};

export const parseDateFormatForAPI = frontendFormat => {
	return frontendFormat === 'DD/MM/YYYY' ? 'dd/mm/yyyy' : 'mm/dd/yyyy';
};

export const defaultTimeFormat = 'h:mma'; // Default locale time format.
export const defaultDateFormat = 'MM/DD/YYYY'; // Default locale date format.
export const defaultUnits = 'imperial'; // Default locale units.
