import bboxPolygon from '@turf/bbox-polygon';
import circle from '@turf/circle';
import bbox from '@turf/bbox';
import { objectHasKeys } from 'utils/helpers';
import { coordinatesRegex } from 'utils/regexHelpers';
import { createFeatureCollection, createPointFeature } from 'utils/geoJsonHelpers';
import { t } from 'i18next';
import {
	liveTrafficLayerId,
	alwaysClickableLayers,
	operatingPictureFillLayerId,
	operatingPictureSymbolLayerId
} from 'constants/mapConstants';
import booleanContains from '@turf/boolean-contains';
import { getLiveTrafficTableColumns } from 'components/airSpace/airspaceUtils';
import { points } from '@turf/helpers';
import center from '@turf/center';
import { getOperatingPictureColumns } from 'components/airSpace/airspaceConstants';
import { uniqBy } from 'lodash-es';

export const createBoxAroundPoint = ({ lat, long }) => {
	const radius = 0.25;
	const options = { units: 'miles' };
	const bufferCircle = circle([long, lat], radius, options);
	const boxBounds = bbox(bufferCircle);
	const square = bboxPolygon(boxBounds);
	const boxCoordinates = square.geometry.coordinates;
	return { boxCoordinates, boxBounds };
};

export const createBoxFeatureCollectionFromPointAndRadius = ({ lat, long, radius }) => {
	const options = { units: 'miles' };
	const bufferCircle = circle([long, lat], radius, options);
	const boxBounds = bbox(bufferCircle);
	const square = bboxPolygon(boxBounds, { id: 'default' });
	return createFeatureCollection({ features: [square], id: 'fromFile' });
};

export const createSourcesStringFromSettingsObject = settings => {
	const urlEncodedEmptyString = '%27%27';
	if (objectHasKeys(settings)) {
		const sources = [];
		for (const [key, value] of Object.entries(settings)) {
			if (value) {
				sources.push(key);
			}
		}
		if (sources.length === 0) {
			return urlEncodedEmptyString;
		}
		return sources.join(',');
	} else {
		//If there are no sources chosen, return an empty string
		return urlEncodedEmptyString;
	}
};

export const toggleMapLayer = ({ currentSettings, layerId }) => {
	const updatedSettings = { ...currentSettings };
	updatedSettings[layerId] = !updatedSettings[layerId];
	return updatedSettings;
};

export const changeMapLayerToggledValue = ({ currentSettings, layerId, value }) => {
	const updatedSettings = { ...currentSettings };
	updatedSettings[layerId] = value;
	return updatedSettings;
};

export const updateLayerSettings = ({ layerToggleList, currentSettings }) => {
	const updatedSettings = { ...currentSettings };
	if (layerToggleList.length > 0) {
		for (const layer of layerToggleList) {
			const hasSetting = Object.hasOwnProperty.call(currentSettings, layer.id);
			if (!hasSetting) {
				updatedSettings[layer.id] = true;
			}
		}
	}
	return updatedSettings;
};

export const looksLikeCoordinates = value => {
	const matchedValue = value.match(coordinatesRegex);
	return matchedValue ? matchedValue.length > 0 : false;
};

export const convertCoordinatesToMapboxLocation = ({ lat, long, optionType }) => {
	return createPointFeature({
		lat,
		long,
		id: `${long}, ${lat}`,
		center: [long, lat],
		address: '-',
		place_name: `LAT: ${Number(lat).toFixed(6)} || LONG: ${Number(long).toFixed(6)}`,
		optionType
	});
};

export const convertResultBoundsToBoundsArray = resultBounds => {
	if (resultBounds && resultBounds.length > 0) {
		const SW = resultBounds[0];
		const NE = resultBounds[1];
		return SW.lat && SW.lng && NE.lat && NE.lng
			? [
					[SW.lng, SW.lat],
					[NE.lng, NE.lat]
				]
			: null;
	} else {
		return null;
	}
};

export const emptyFeatureCollection = {
	type: 'FeatureCollection',
	features: []
};

export const feetInMile = 5280;

export const convertMilesToFeet = miles => {
	return miles * feetInMile;
};

export const getMapBoundsArray = map => {
	const mapBounds = map.getBounds();
	return [
		mapBounds._sw.lat.toFixed(6),
		mapBounds._sw.lng.toFixed(6),
		mapBounds._ne.lat.toFixed(6),
		mapBounds._ne.lng.toFixed(6)
	];
};

export const convertBoundsQueryParamToBoundsArray = boundsQP => {
	const boundsArray = boundsQP.split(',').map(Number);
	return [
		[boundsArray[1], boundsArray[0]],
		[boundsArray[3], boundsArray[2]]
	];
};

export const getMapBoundingBox = map => {
	const mapBounds = map.getBounds();
	const minX = mapBounds._sw.lng;
	const minY = mapBounds._sw.lat;
	const maxX = mapBounds._ne.lng;
	const maxY = mapBounds._ne.lat;
	return [minX, minY, maxX, maxY];
};

export const getMapboxBoundsFromBoundingBox = boundingBox => {
	//handle if those are undefined
	if (!boundingBox) {
		return null;
	}
	return [
		[boundingBox[0], boundingBox[1]],
		[boundingBox[2], boundingBox[3]]
	];
};

export const getSelectedMapLayerList = ({ allLayers, currentSettings }) => {
	return allLayers.filter(layer => currentSettings[layer?.id]);
};

export const getMapLayerControlsSections = ({ layers, sources }) => {
	const layersList = layers.map(l => ({
		...l,
		isLayer: true
	}));

	const sourcesList = sources.map(s => ({
		...s,
		isSource: true,
		title: s.name,
		thumbnail_url: s.img_url
	}));

	const allItems = [...layersList, ...sourcesList];

	const sectionsLayout = [
		{
			title: t('Base Map'),
			id: 'base_map',
			itemIds: ['hillshade']
		},
		{
			title: t('Air Traffic Layers'),
			id: 'air_traffic_layers',
			itemIds: ['kh-laanc', 'notify-and-fly']
		},
		{
			title: t('National Airspace'),
			id: 'national_airspace',
			itemIds: [
				'uasfm-airspace',
				'faa-tfr',
				'faa-sua',
				'faa-nsufr',
				'faa-airspace-boundary',
				'faa-airports',
				'recreational-flyer-sites',
				'identification_areas',
				'military-training-routes'
			]
		},
		{
			title: t('Local Advisories'),
			id: 'local_advisories',
			itemIds: ['geoportal', 'local', 'faa-stadium', 'national-parks']
		},
		{
			title: t('Custom Airspace'),
			id: 'custom_airspace',
			itemIds: ['kh-customairspace', 'italy_atm09_info', ...layersList.map(l => l.id)]
		}
	];

	const sections = sectionsLayout.map(s => {
		return {
			...s,
			items: s.itemIds.flatMap(id =>
				allItems.filter(index => {
					return index.id === id;
				})
			)
		};
	});
	return sections;
};

export const getClickableTileServerMapLayerIds = visibleMapLayers => {
	return visibleMapLayers.filter(l => l.preview_properties).map(l => l.id);
};

export const getMapFeaturesToShow = ({ newClickedFeatures, visibleMapLayers, zoom, units }) => {
	const layersWithinZoom = visibleMapLayers?.filter(layer => {
		return layer?.preview_properties?.zoom_min < zoom && layer?.preview_properties?.zoom_max > zoom;
	});

	const newClickedFeaturesToShow = newClickedFeatures?.filter(feature => {
		return alwaysClickableLayers.includes(feature.layer.id)
			? true
			: layersWithinZoom.find(layer => layer.id === feature.layer.id);
	});

	const uniqueFeatures = uniqBy(newClickedFeaturesToShow, feature => feature.properties.id);

	const formattedFeaturesToShow = uniqueFeatures.map(feature => {
		const parentLayer = layersWithinZoom.find(layer => layer.id === feature.layer.id);
		let preview_properties = [];
		if (parentLayer?.preview_properties?.properties) {
			preview_properties = parentLayer.preview_properties.properties;
		} else {
			if (feature.layer.id === liveTrafficLayerId) {
				preview_properties = getLiveTrafficTableColumns({ isForPreviewProp: true, units });
			}
			if (
				feature.layer.id === operatingPictureFillLayerId ||
				feature.layer.id === operatingPictureSymbolLayerId
			) {
				preview_properties = getOperatingPictureColumns(true);
			}
		}

		return {
			...feature,
			preview_properties
		};
	});

	return formattedFeaturesToShow;
};

export const filterMapFeaturesByBoundingBox = ({ features, boundingBox }) => {
	const mapBoundsPolygon = bboxPolygon(boundingBox);
	return features.filter(feature => {
		if (!feature.geometry) {
			return false;
		}
		return booleanContains(mapBoundsPolygon, feature);
	});
};

// Turf.js helper
/**
 *
 * @param {Array} ex: [[longitude, latitude],[-112.2394578, 33.294537]]
 */
export const getPolygonCenterFeature = array => {
	const features = points(array);
	return center(features);
};

export const findCenterOfBounds = mapBounds => {
	//Map bounds is this shape: 	[minX, minY, maxX, maxY]
	const boundsPolygon = bboxPolygon(mapBounds);
	const centerFeature = center(boundsPolygon);
	return centerFeature.geometry.coordinates;
};
