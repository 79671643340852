import queryString from 'query-string';

/**
 *
 * @description take in string parameter, return each parameter in an Array
 * @function parseUrlParameter
 * @return { Array }
 */
export const parseUrlParameter = UrlParameter => {
	const splitQuestionMark = UrlParameter.split('?');
	const splitAmpersand = splitQuestionMark.reduce((accumulator, element) => {
		return [...accumulator, element && element.includes('&') ? element.split('&') : element];
	}, []);
	// Flatten nested Array
	const flattenArray = splitAmpersand.concat.apply([], splitAmpersand);
	return flattenArray.filter(Boolean);
};

/**
 *
 * @description
 * @function updatePaginationPage
 * @return { Array of url params }
 */
export const incrementPageNumber = object => {
	const updated = {
		...object,
		page: object.page ? Number(object.page) + 1 : 2
	};
	return queryString.stringify(updated);
};

/**
 * @description create request url Parameter
 * @param ( param ) url parameter if valid
 * @param ( pagination ) pagination object in last response
 * @param ( lastRequestUrl ) last requested url
 * @function setUrlParameter
 * @return { string }
 */
export const setUrlParameter = ({ param, lastRequestUrl, endpoint }) => {
	const lastRequestUrlEndpoint = lastRequestUrl ? lastRequestUrl.split('?')[0] : '';
	const previousEndpoint = getLastItemInUrlPath(lastRequestUrlEndpoint);
	const lastRequestParameters = lastRequestUrl ? lastRequestUrl.split('?')[1] : '';
	const lastRequestParametersObject = queryString.parse(lastRequestParameters);
	const newParametersObject = queryString.parse(param);

	const isSameEndpoint = previousEndpoint === endpoint; //Ensure it is a subsequent request to the same endpoint

	if (lastRequestUrl && isSameEndpoint) {
		//This is a subsequent request
		//Add any new params that are passed in
		//Increment the page number
		const updatedParameters = {
			...lastRequestParametersObject,
			...newParametersObject
		};
		return incrementPageNumber(updatedParameters);
	} else {
		//This is the first request
		//Return the params that were passed in or an empty string if no params
		return param ? param : '';
	}
};

/**
 * return account # from url
 * @function findAccountIdFromUrl
 * @param { string}
 * @returns { number }
 */
export const findAccountIdFromUrl = url => {
	const urlRegex = /(account\/\d+\/)/g;
	const matched = url.match(urlRegex, function (matched) {
		return matched;
	});
	if (!matched) return;
	const splitUrl = matched[0].split('/');
	return Number.parseInt(splitUrl[1]);
};

/**
 * return activeTable from pathname for manage section
 * @function getActiveTableFromPathname
 * @param pathname
 * @returns {string}
 */

export const getActiveTableFromPathname = pathname => {
	const urlRegex = /(manage\/.+\b)/g;
	const matched = pathname.match(urlRegex, function (matched) {
		return matched;
	});
	if (!matched) return;
	const splitUrl = matched[0].split('/');
	const manageRoute = splitUrl[1];
	return manageRoute.toLowerCase();
};

/**
 * return activeTable from pathname for reports tables
 * @function getActiveReportFromPathname
 * @param pathname
 * @returns {string}
 */

export const getActiveReportFromPathname = pathname => {
	const urlRegex = /(reports\/.+\b)/g;
	const matched = pathname.match(urlRegex, function (matched) {
		return matched;
	});
	if (!matched) return;
	const splitUrl = matched[0].split('/');
	const manageRoute = splitUrl[1];
	return manageRoute.toLowerCase();
};

export const getLastItemInUrlPath = pathname => {
	const pathArray = pathname.split('/');
	const pathLength = pathArray.length;
	return pathArray[pathLength - 1];
};

export const getEndpointFromRoute = value => {
	const list = {
		aircraft: 'aircraft',
		'aircraft-retired': 'aircraft',
		alerts: 'alerts',
		authorizations: 'laanc',
		'auto-tags': 'auto-tags',
		batteries: 'batteries',
		'batteries-retired': 'batteries',
		checklists: 'checklists',
		'checklist-logs': 'checklist-executions',
		connections: 'connections',
		'custom-airspace-layers': 'airspace-feature',
		files: 'files',
		'files-media': 'files',
		flights: 'flights',
		'flight-logs': 'flight-logs',
		'flight-duplicates': 'flight-duplicates',
		incidents: 'incidents',
		maintenance: 'maintenance',
		'maintenance-recommendations': 'maintenance-recommendations',
		'maintenance-schedules': 'schedules',
		missions: 'missions',
		'risk-assessments': 'risk-assessments',
		'risk-assessment-logs': 'risk-assessment-executions',
		roles: 'role',
		tags: 'tag-groups',
		users: 'users',
		'users-invites': 'invite',
		'users-retired': 'users',
		workflows: 'workflows'
	};
	return list[value] || value;
};

export const getActiveMainRoute = pathname => {
	const urlRegex = /(account\/\w+\/\w+\b)/g;
	const matched = pathname.match(urlRegex, function (matched) {
		return matched;
	});
	if (!matched) return;
	const splitUrl = matched[0].split('/');
	const mainRoute = splitUrl[2];
	return mainRoute;
};

export const getQueryParamsFromLocation = location => {
	const queryParameters = location.search;
	const currentParameters = queryString.parse(queryParameters, {
		parseBooleans: true,
		arrayFormat: 'bracket',
		sort: false
	});
	return currentParameters;
};

export const convertQueryStringToObj = string => {
	return queryString.parse(string, {
		parseBooleans: true,
		arrayFormat: 'bracket',
		sort: false
	});
};

export const setQueryParamsFromObject = (queryObject, navigate) => {
	navigate({
		search: stringifyQueryObj(queryObject)
	});
};

export const stringifyQueryObj = queryObject => {
	return queryString.stringify(queryObject, {
		arrayFormat: 'bracket',
		skipNull: false,
		skipEmptyString: false,
		sort: false
	});
};

export const removeLatLongFromQueryParams = (currentParameters, navigate) => {
	let updatedParameters = {
		...currentParameters
	};
	delete updatedParameters.lat;
	delete updatedParameters.long;
	setQueryParamsFromObject(updatedParameters, navigate);
};

export const manageUrlAppends = value => {
	let appendsQueryString = '';
	switch (value) {
		case 'missions': {
			appendsQueryString = {
				appends: [
					'mission_participants',
					'airspace',
					'tags',
					'address',
					'mission_area',
					'risk_score',
					'flight_count',
					'total_flight_duration',
					'laanc_auth_count'
				]
			};
			break;
		}
		case 'flights': {
			appendsQueryString = {
				appends: [
					'mission',
					'user',
					'address',
					'aircraft',
					'tags',
					'photo_count',
					'flight_ended',
					'laanc_auth_count',
					'airspace'
				]
			};
			break;
		}
		case 'flight-duplicates': {
			appendsQueryString = {
				appends: [
					'target_flight',
					'target_flight.address',
					'target_flight.aircraft',
					'target_flight.mission',
					'target_flight.user',
					'source_flight',
					'source_flight.address',
					'source_flight.aircraft',
					'source_flight.mission',
					'source_flight.user',
					'user'
				]
			};
			break;
		}
		case 'flight-logs': {
			appendsQueryString = {
				appends: ['flight', 'user', 'files', 'processing_status']
			};
			break;
		}
		case 'maintenance': {
			appendsQueryString = {
				appends: ['user', 'aircraft', 'tags', 'operator']
			};
			break;
		}
		case 'schedules': {
			appendsQueryString = {
				appends: ['schedule_targets', 'target_count']
			};
			break;
		}
		case 'maintenance-recommendations': {
			appendsQueryString = {
				appends: ['schedule', 'aircraft']
			};
			break;
		}
		case 'incidents': {
			appendsQueryString = {
				appends: ['user', 'aircraft', 'tags']
			};
			break;
		}
		case 'aircraft': {
			appendsQueryString = {
				appends: [
					'total_flight_hours',
					'user',
					'tags',
					'flight_count',
					'last_flown',
					'maintenance_count',
					'crash_repair_count',
					'incident_count',
					'registration_expiration',
					'is_compliant'
				]
			};
			break;
		}
		case 'batteries': {
			appendsQueryString = {
				appends: ['tags']
			};
			break;
		}
		case 'workflows': {
			appendsQueryString = {
				appends: ['user_count', 'workflow_steps', 'tags']
			};
			break;
		}
		case 'checklists': {
			appendsQueryString = {
				appends: ['tags']
			};
			break;
		}
		case 'checklist-executions': {
			appendsQueryString = {
				appends: ['checklist', 'user']
			};
			break;
		}
		case 'risk-assessments': {
			appendsQueryString = {
				appends: ['tags']
			};
			break;
		}
		case 'risk-assessment-executions': {
			appendsQueryString = {
				appends: ['risk_assessment', 'user']
			};
			break;
		}
		case 'users': {
			appendsQueryString = {
				appends: [
					'role',
					'total_flight_hours',
					'faa_107_renewal',
					'tags',
					'flight_count',
					'last_flown',
					'is_compliant'
				]
			};
			break;
		}
		case 'tag-groups': {
			appendsQueryString = {
				appends: ['tags']
			};
			break;
		}
		case 'laanc': {
			appendsQueryString = {
				appends: ['pilot', 'context', 'applied_state']
			};
			break;
		}
		case 'alerts': {
			appendsQueryString = {
				appends: ['alertRecipients', 'alertRecipients.detail', 'user', 'schedule']
			};
			break;
		}
		case 'auto-tags': {
			appendsQueryString = {
				appends: ['tagGroup', 'tagGroup.tags']
			};
			break;
		}
		case 'files': {
			appendsQueryString = {
				appends: ['file_associations', 'file_associations_count']
			};
			break;
		}
		default: {
			appendsQueryString = '';
		}
	}
	return stringifyQueryObj(appendsQueryString);
};

export const removeRetiredQueryParamFromString = queryString => {
	let queryObject = convertQueryStringToObj(queryString);
	delete queryObject.retired;
	return stringifyQueryObj(queryObject);
};

/**
 * get url param and return target key value
 * @function getParamKeyValue
 * @param {object, string}
 * @returns {string}
 */
export const getParamKeyValue = (location, target) => {
	const queryParameters = getQueryParamsFromLocation(location);
	return queryParameters[target];
};

/**
 * append param to url
 * @function appendParamToUrl
 * @param {string, object}
 * @returns {}
 */
export const appendParamToUrl = (parameter, currentParameters, navigate) => {
	const incomingParameter = queryString.parse(parameter);
	const updatedParameters = {
		...currentParameters,
		...incomingParameter
	};
	setQueryParamsFromObject(updatedParameters, navigate);
};

export const useV1Endpoint = e => {
	const v0Endpoints = ['airspace-feature', 'invite', 'role'];

	if (v0Endpoints.includes(e)) {
		return false;
	} else {
		return true;
	}
};
