import { CURRENT_ACCOUNT_ID, REFRESH_TOKEN, TOKEN } from 'constants/localStorageConstants';
import {
	getSelectedMapStyleFromLS,
	getSelectedMapSourcesFromLS,
	getSelectedMapBoxStyleFromLS,
	getAirspaceMapBoundsFromLS,
	getAirspaceMapPinFromLS,
	getUnitsFromLS
} from 'actions/localStorage';
import { emptyFeatureCollection } from 'utils/mapUtils';
import { browserTimeZone } from 'utils/timeHelpers';

export const initialGlobalState = {
	isLoading: false,
	showReloadMsg: { isOpen: false, versionUpdate: null },
	snackbarAlerts: [
		//see structure in AlertMessage.js propTypes
	],
	allAircraftMakes: [],
	allAircraftModels: [],
	allBatteryMakes: [],
	allBatteryModels: [],
	accountAircraftModels: [],
	components: [],
	trafficPanelOpen: false,
	accountTagGroups: [],
	accountRoles: [],
	maintenanceSchedules: [],
	units: getUnitsFromLS()
};

export const initialUserAuthState = {
	token: localStorage.getItem(TOKEN),
	refreshToken: localStorage.getItem(REFRESH_TOKEN),
	currentAccountId: localStorage.getItem(CURRENT_ACCOUNT_ID)
		? Number(localStorage.getItem(CURRENT_ACCOUNT_ID))
		: null,
	amplifyError: null,
	amplifyAlert: null,
	accountExpiringDialogOpen: false,
	requestingResetCode: false,
	resetCodeSent: false
};

export const initialMapState = {
	mapLoading: false,
	userCoordinate: null,
	markerDetails: null,
	loading: false,
	userLocationLoading: false,
	locationDetailsLoading: false,
	style: getSelectedMapStyleFromLS(),
	styleOptions: [],
	mapboxSources: [],
	aloftSources: [],
	visibleMapboxSources: getSelectedMapSourcesFromLS(),
	tileServerLayers: [],
	visibleMapLayers: [],
	sources: [],
	styleConfig: null,
	weatherLoading: false,
	weather: null,
	advisoriesLoading: false,
	advisory: null,
	geolocationError: null,
	featureCollection: emptyFeatureCollection,
	updateMapFromFile: false,
	smallDrawInstance: null,
	largeDrawInstance: null,
	selectedFeatures: [],
	radiusObject: {},
	unauthenticatedStyle: getSelectedMapBoxStyleFromLS(),
	mapReadyForDrawing: false,
	clickedFeatures: [],
	searchOptions: [],
	mapBoundingBox: null,
	airspaceMapBounds: getAirspaceMapBoundsFromLS(),
	airspaceMapPin: getAirspaceMapPinFromLS(),
	renderMap: true,
	showFeatureCard: false,
	mapPinInView: true
};

export const initialActivitiesState = {
	activitiesList: [],
	editModeId: false
};

export const initialFileUploadState = {
	fileLoading: false,
	preUploadFilesList: [],
	filesList: []
};

export const initialFlightPathViewerState = {
	data: [],
	dataLoading: false
};

export const initialLaancState = {
	laancCreateType: 'laanc-107',
	laancEditMode: { isEdit: false, selectedId: '' },
	laancCreateCurrentStep: 1,
	laancPayload: {},
	laancCheckResult: [],
	laancCreateTimestamp: { date: null, hours: 2, mins: 0 },
	laancCreateStartTime: null,
	laancCreatePreCheckValid: false,
	laancWithInDistance: true,
	laancCeiling: 0,
	laancAirspaceStatus: {
		outsideAirspace: null,
		FC: null, // futher cooordination
		AA: null // auto approval
	},
	laancAltitude: 0,
	laancCreatePilot: null,
	laancCreateSafetyJustification: '',
	laancShapeValid: true,
	timezone: browserTimeZone
};

export const initialLiveTrafficState = {
	airMeshFeatures: [],
	airMeshWebsocketConnected: false,
	airMeshConnectionError: false,
	airMeshConnectionLoading: false,
	airMeshPusherInstance: null,
	pauseTrafficUpdates: false,
	airMeshAircraftTypes: [],
	airMeshProviderTypes: [],
	airMeshSignalSourceTypes: [],
	aircraftTypesFilterValue: [],
	providerTypesFilterValue: [],
	operatingPictureFeatures: [],
	operatingPictureTypesFilterValue: [],
	operatingPictureSourcesFilterValue: [],
	signalSourcesFilterValue: [],
	ownshipIDs: [],
	airMeshChannels: [],
	operatingPicturePagination: {
		currentPage: 1,
		totalPages: 1,
		loadedPages: [],
		isLoading: false,
		hasMorePages: true,
		isInitialLoad: true,
		tempFeatures: []
	},
	hideSimulatedTraffic: false,
	hasSimulatedTraffic: false
};

export const initialManageTablesState = {
	tableLoading: false,
	manageLastRequestUrl: null,
	manageModal: {
		isOpen: false,
		selected: null
	},
	manageDrawer: {
		isOpen: false,
		drawerState: 'view',
		selectedID: null,
		isEditMode: false,
		data: {}
	},
	manageSortedTable: {
		list: [],
		activeTable: null,
		sortBy: null,
		sortDirection: true,
		initialLoad: true
	},
	managePagination: {
		current_page: 0,
		last_page: 0,
		limit: 0,
		offset: 0,
		total: 0
	},
	manageAddUser: {
		modal: {
			isOpen: false
		},
		payload: {}
	},
	selectedTableRows: [],
	showCheckboxes: false,
	taggableModelsCount: null,
	tableCSVDownloading: false,
	hoverMapItems: [],
	zoomToResultsBounds: false,
	loadingFilterValues: false,
	detailDrawerLoading: false,
	manageFilterMenuOpen: false,
	missionPlanningDrawerOpen: false,
	flightMergeLoading: null,
	loadedPages: []
};

export const initialMissionState = {
	id: null,
	title: '',
	status: '',
	starts_at: null,
	notes: '',
	mission_participants: [],
	timezone: browserTimeZone,
	days: 0,
	hours: 0,
	minutes: 30,
	pre_assigned_aircraft: [],
	pre_assigned_batteries: [],
	tags: [],
	workflow: null,
	sgiWaiverLoadingCount: 0
};

export const initialReportsState = {
	reportsTotals: null,
	reportsLoading: false,
	activeReports: {
		columns: [],
		rows: []
	},
	visualData: [],
	dashboardLoading: false
};

export const initialRolesState = {
	rolesPermAssignedList: []
};

export const initialStreamingState = {
	streamMicEnabled: null,
	streamsList: [],
	streamsInitiallyLoaded: false,
	streamsLoading: false,
	mapView: true,
	equalView: true,
	selectedStreams: [],
	drawerOpen: true,
	shareTokenLoading: false,
	mapBounds: null,
	micPermissionGranted: false,
	micChecked: false
};
