import { t } from 'i18next';

export const degreeCelToFar = value => {
	return Math.round((value * 9) / 5 + 32);
};

export const convertToPercent = (value, length_ = 0) => {
	return Number((value * 100).toFixed(length_));
};

export const metersPerSecondToMilesPerHour = (value, length_ = 0) => {
	return Number((value * 2.236_94).toFixed(length_));
};

export const kmToMi = (value, length_ = 0) => {
	return Number((value * 0.621_371).toFixed(length_));
};

export const meterToMi = (value, length_ = 0) => {
	return Number((value * 0.000_621_371).toFixed(length_));
};

export const meterToFt = (value, length_ = 0) => {
	return Number((value * 3.280_84).toFixed(length_));
};

export const getRoundPercent = (total, value) => {
	return Math.round((value / total) * 100);
};

export const feetToMeter = (value, length_ = 0) => {
	return Number((value * 0.304_8).toFixed(length_));
};

export const metersPerSecondToKmPerHour = (value, length_ = 0) => {
	return Number((value * 3.6).toFixed(length_));
};

export const unitOptions = () => [
	{ label: t('Metric'), value: 'metric' },
	{ label: t('Imperial'), value: 'imperial' }
];
