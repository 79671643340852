import configureMeasurements from 'convert-units';

import { reduxStore } from 'services/configureStore';

//Conditional import needed so that tests don't break due to problem with library
let allMeasures;

if (process.env.NODE_ENV !== 'test') {
	allMeasures = require('convert-units/definitions/all').default;
} else {
	allMeasures = {};
}

// Configure unit conversion
const convertMeasurements = configureMeasurements(allMeasures);
const allUnits = convertMeasurements().list();
const metricUnits = new Set(
	allUnits.filter(unit => unit.system === 'metric').map(unit => unit.abbr)
);
const imperialUnits = new Set(
	allUnits.filter(unit => unit.system === 'imperial').map(unit => unit.abbr)
);

export const getUnits = () => reduxStore.getState().global.units; // Directly fetch from Redux

export const convert = (value, fromUnit, toUnit, options = {}) => {
	const { addLabel = false, decimals = 2 } = options;
	const userUnitSystem = getUnits();

	const isMetric = userUnitSystem === 'metric';
	const isInputMetric = metricUnits.has(fromUnit);
	const isInputImperial = imperialUnits.has(fromUnit);

	// Helper function to round values
	const roundValue = (val, unit) => {
		if (['C', 'F'].includes(unit)) {
			return Math.round(val);
		}
		const factor = Math.pow(10, decimals);
		return Math.round(val * factor) / factor;
	};

	// Helper function to format output
	const formatOutput = (val, unit) =>
		addLabel ? (['C', 'F'].includes(unit) ? `${val}°${unit}` : `${val} ${unit}`) : val;

	// If input unit matches user preference, return as is
	if ((isMetric && isInputMetric) || (!isMetric && isInputImperial)) {
		return formatOutput(roundValue(value, fromUnit), fromUnit);
	}

	// Convert units if needed
	try {
		const convertedValue = convertMeasurements(value).from(fromUnit).to(toUnit);
		return formatOutput(roundValue(convertedValue, toUnit), toUnit);
	} catch (error) {
		console.error(`Conversion error: ${error}`);
		return formatOutput(roundValue(value, fromUnit), fromUnit);
	}
};
