import {
	SELECTED_MAP_LAYERS,
	SELECTED_MAP_STYLE,
	SELECTED_MAP_SOURCES,
	SELECTED_MAPBOX_STYLE,
	SELECTED_SEARCH_TYPE,
	LIVE_TRAFFIC_COLUMNS,
	SGI_WAIVER_OPERATOR_ADDRESS,
	AIRSPACE_MAP_BOUNDS,
	AIRSPACE_MAP_PIN,
	OPERATING_PICTURE_COLUMNS,
	TIME_FORMAT,
	DATE_FORMAT,
	UNITS
} from 'constants/localStorageConstants';
import { defaultMapStyle, defaultMapboxStyleValue } from 'constants/mapConstants';
import { getLocationSearchOption } from 'components/airSpace/airspaceConstants';
import {
	parseDateFormatFromAPI,
	parseTimeFormatFromAPI,
	defaultTimeFormat,
	defaultDateFormat,
	defaultUnits
} from 'components/accountSettings/accountSettingsConstants';
import { setUnits } from 'reducers/globalSlice';

export const saveSelectedMapLayersToLS = layers => {
	localStorage.setItem(SELECTED_MAP_LAYERS, JSON.stringify(layers));
};

export const getSelectedMapLayersFromLS = () => {
	const layerSettings = localStorage.getItem(SELECTED_MAP_LAYERS);
	return layerSettings ? JSON.parse(layerSettings) : {};
};

export const saveSelectedMapSourcesToLS = layers => {
	localStorage.setItem(SELECTED_MAP_SOURCES, JSON.stringify(layers));
};

export const getSelectedMapSourcesFromLS = () => {
	const sourceSettings = localStorage.getItem(SELECTED_MAP_SOURCES);
	return sourceSettings ? JSON.parse(sourceSettings) : {};
};

export const saveSelectedMapStyleToLS = styleId => {
	localStorage.setItem(SELECTED_MAP_STYLE, styleId);
};

export const getSelectedMapStyleFromLS = () => {
	const selectedMapStyle = localStorage.getItem(SELECTED_MAP_STYLE);
	return selectedMapStyle ? selectedMapStyle : defaultMapStyle;
};

export const saveSelectedMapboxStyleToLS = styleId => {
	localStorage.setItem(SELECTED_MAPBOX_STYLE, styleId);
};

export const getSelectedMapBoxStyleFromLS = () => {
	const selectedMapStyle = localStorage.getItem(SELECTED_MAPBOX_STYLE);
	return selectedMapStyle ? selectedMapStyle : defaultMapboxStyleValue;
};

export const saveSelectedSearchTypeToLS = searchType => {
	localStorage.setItem(SELECTED_SEARCH_TYPE, JSON.stringify(searchType));
};

export const getSelectedSearchTypeFromLS = () => {
	const selectedSearchType = localStorage.getItem(SELECTED_SEARCH_TYPE);
	return selectedSearchType ? JSON.parse(selectedSearchType) : getLocationSearchOption();
};

export const getLiveTrafficColumnsFromLS = () => {
	const liveTrafficColumns = localStorage.getItem(LIVE_TRAFFIC_COLUMNS);
	return liveTrafficColumns ? JSON.parse(liveTrafficColumns) : [];
};

export const saveLiveTrafficColumnsToLS = columns => {
	localStorage.setItem(LIVE_TRAFFIC_COLUMNS, JSON.stringify(columns));
};

export const saveSGIWaiverOperatorAddressToLS = address => {
	localStorage.setItem(SGI_WAIVER_OPERATOR_ADDRESS, address);
};

export const getSGIWaiverOperatorAddressFromLS = () => {
	const savedAddress = localStorage.getItem(SGI_WAIVER_OPERATOR_ADDRESS);
	return savedAddress ? savedAddress : '';
};

export const saveAirspaceMapBoundsToLS = bounds => {
	localStorage.setItem(AIRSPACE_MAP_BOUNDS, JSON.stringify(bounds));
};

export const getAirspaceMapBoundsFromLS = () => {
	const savedBounds = localStorage.getItem(AIRSPACE_MAP_BOUNDS);
	return savedBounds ? JSON.parse(savedBounds) : null;
};

export const saveAirspaceMapPinToLS = pin => {
	localStorage.setItem(AIRSPACE_MAP_PIN, JSON.stringify(pin));
};

export const getAirspaceMapPinFromLS = () => {
	const savedPin = localStorage.getItem(AIRSPACE_MAP_PIN);
	return savedPin ? JSON.parse(savedPin) : null;
};

export const getOperatingPictureColumnsFromLS = () => {
	const operatingPictureCols = localStorage.getItem(OPERATING_PICTURE_COLUMNS);
	return operatingPictureCols ? JSON.parse(operatingPictureCols) : [];
};

export const saveOperatingPictureColumnsToLS = columns => {
	localStorage.setItem(OPERATING_PICTURE_COLUMNS, JSON.stringify(columns));
};

export const saveTimeFormatToLS = timeFormat => {
	localStorage.setItem(TIME_FORMAT, timeFormat);
};

export const getTimeFormatFromLS = () => {
	const savedTimeFormat = localStorage.getItem(TIME_FORMAT);
	return savedTimeFormat ? savedTimeFormat : defaultTimeFormat;
};

export const saveDateFormatToLS = dateFormat => {
	localStorage.setItem(DATE_FORMAT, dateFormat);
};

export const getDateFormatFromLS = () => {
	const savedDateFormat = localStorage.getItem(DATE_FORMAT);
	return savedDateFormat ? savedDateFormat : defaultDateFormat;
};

export const getUnitsFromLS = () => {
	const savedUnits = localStorage.getItem(UNITS);
	return savedUnits ? savedUnits : defaultUnits;
};

export const saveUnitsToLS = units => {
	localStorage.setItem(UNITS, units);
};

export const setAccountSettingsToLS =
	(settings = {}) =>
	dispatch => {
		const units = settings.default_units ? settings.default_units : defaultUnits;
		const dateFormat = settings.default_date_format
			? parseDateFormatFromAPI(settings.default_date_format)
			: defaultDateFormat;
		const timeFormat = settings.default_time_format
			? parseTimeFormatFromAPI(settings.default_time_format)
			: defaultTimeFormat;

		// Only set if values don't exist in localStorage
		if (!localStorage.getItem(UNITS)) {
			saveUnitsToLS(units);
			dispatch(setUnits(units));
		}
		if (!localStorage.getItem(DATE_FORMAT)) {
			saveDateFormatToLS(dateFormat);
		}
		if (!localStorage.getItem(TIME_FORMAT)) {
			saveTimeFormatToLS(timeFormat);
		}
	};
