import { createSlice } from '@reduxjs/toolkit';
import { initialLaancState } from 'reducers/initialStates';

const laancSlice = createSlice({
	name: 'laanc',
	initialState: {
		//Set by configureStore
	},
	reducers: {
		resetLaancSlice() {
			return initialLaancState;
		},
		setLaancCreateType(state, action) {
			state.laancCreateType = action.payload;
		},
		setLaancCreateCurrentStep(state, action) {
			state.laancCreateCurrentStep = action.payload;
		},
		setLaancPayload(state, action) {
			state.laancPayload = action.payload;
		},
		setLaancCheckResult(state, action) {
			state.laancCheckResult = action.payload;
		},
		setLaancCreateTimestamp(state, action) {
			state.laancCreateTimestamp = action.payload;
		},
		setLaancCreateStartTime(state, action) {
			state.laancCreateStartTime = action.payload;
		},
		setLaancCreatePreCheckValid(state, action) {
			state.laancCreatePreCheckValid = action.payload;
		},
		setLaancWithInDistance(state, action) {
			state.laancWithInDistance = action.payload;
		},
		setLaancCeiling(state, action) {
			state.laancCeiling = action.payload;
		},
		setLaancAirspaceStatus(state, action) {
			state.laancAirspaceStatus = action.payload;
		},
		setLaancAltitude(state, action) {
			state.laancAltitude = action.payload;
		},
		setLaancCreatePilot(state, action) {
			state.laancCreatePilot = action.payload;
		},
		setLaancCreateSafetyJustification(state, action) {
			state.laancCreateSafetyJustification = action.payload;
		},
		setLaancShapeValid(state, action) {
			state.laancShapeValid = action.payload;
		},
		setLaancTimezone(state, action) {
			state.timezone = action.payload;
		},
		setDuplicateLaanc(state, action) {
			return {
				...state,
				...action.payload
			};
		},
		setLaancFromMission(state, action) {
			return {
				...state,
				...action.payload
			};
		},
		hydrateLaanc(state, action) {
			return {
				...state,
				...action.payload
			};
		},
		updateLaancPayloadOperation(state, action) {
			state.laancPayload = {
				...state.laancPayload,
				operation: action.payload
			};
		}
	}
});

export const {
	resetLaancSlice,
	setLaancCreateType,
	setLaancCreateCurrentStep,
	setLaancPayload,
	setLaancCheckResult,
	setLaancCreateTimestamp,
	setLaancCreateStartTime,
	setLaancCreatePreCheckValid,
	setLaancWithInDistance,
	setLaancCeiling,
	setLaancAirspaceStatus,
	setLaancAltitude,
	setLaancSubmitError,
	setLaancCreatePilot,
	setLaancCreateSafetyJustification,
	setLaancShapeValid,
	setLaancTimezone,
	setDuplicateLaanc,
	setLaancFromMission,
	hydrateLaanc,
	updateLaancPayloadOperation
} = laancSlice.actions;

export default laancSlice.reducer;

export const laancSelector = state => state.laanc;
