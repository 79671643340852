//Source: https://www.taniarascia.com/websockets-in-redux/

import { handlePusherConnect, handlePusherDisconnect } from 'utils/pusherHelpers';
import { subscribeToSSE, unsubscribeFromSSE } from 'utils/serverSendEventHelpers';
import { featuresSelector } from 'reducers/userAuthSlice';
export const airMeshMiddleware = () => parameters => next => action => {
	const { type } = action;
	const { dispatch, getState } = parameters;
	const sseEnabled = featuresSelector(getState())?.airmesh_sse;

	switch (type) {
		case 'airMesh/connect': {
			if (sseEnabled) {
				// Reset connection states before attempting new connection
				dispatch(unsubscribeFromSSE());
				dispatch(subscribeToSSE());
			} else {
				dispatch(handlePusherConnect());
			}
			break;
		}
		case 'airMesh/disconnect': {
			if (sseEnabled) {
				dispatch(unsubscribeFromSSE());
			} else {
				dispatch(handlePusherDisconnect());
			}
			break;
		}
		default: {
			break;
		}
	}

	return next(action);
};

export const airMeshConnect = payload => ({ type: 'airMesh/connect', payload });
export const airMeshDisconnect = () => ({ type: 'airMesh/disconnect' });
