import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { reduxStore } from 'services/configureStore';
import AppThemeProvider from './AppThemeProvider';
import * as Sentry from '@sentry/react';
import { MUI_KEY, INTERCOM_ID } from 'constants/environmentVariables';
import { IntercomProvider } from 'react-use-intercom';
import { LocaleConnector } from 'LocaleConnector';
import { LicenseInfo } from '@mui/x-license';
import { UnitProvider } from 'components/UnitProvider';

LicenseInfo.setLicenseKey(MUI_KEY);

const Root = ({ children }) => {
	return (
		<Provider store={reduxStore}>
			<LocaleConnector />
			<UnitProvider>
				{/*//shouldInitialize reads env var "TEST" to prevent Intercom from causing tests to time out*/}
				<IntercomProvider
					appId={INTERCOM_ID}
					autoBoot
					autoBootProps={{
						hideDefaultLauncher: true,
						alignment: 'left',
						horizontalPadding: 20,
						verticalPadding: 120
					}}
					shouldInitialize={process.env.TEST !== 'true'}
				>
					<AppThemeProvider>
						<BrowserRouter>{children}</BrowserRouter>
					</AppThemeProvider>
				</IntercomProvider>
			</UnitProvider>
		</Provider>
	);
};

export default Sentry.withProfiler(Root);
