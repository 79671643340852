import { fetchAirMeshToken, fetchAirMeshChannels } from 'apis/airspace.api';
import {
	setAMConnectionLoading,
	setAMConnectionError,
	setAMWebSocketConnected,
	resetAMState,
	airMeshChannelsSelector
} from 'reducers/liveTrafficSlice';
import { processData } from 'utils/airMeshHelpers';
import { fetchEventSource } from '@microsoft/fetch-event-source';

let abortController = null;
let tokenRefreshTimeout = null;
let pollingInterval = null;

const getJWTExpiration = token => {
	const payload = JSON.parse(atob(token.split('.')[1]));
	return payload.exp * 1000;
};

const scheduleTokenRefresh = expiresAt => (dispatch, getState) => {
	const timeUntilExpiry = expiresAt - Date.now();
	const refreshBuffer = 2 * 60 * 1000; // 2 minutes
	const refreshTime = Math.max(0, timeUntilExpiry - refreshBuffer);

	if (tokenRefreshTimeout) {
		clearTimeout(tokenRefreshTimeout);
	}

	const refresh = async (isRetry = false) => {
		try {
			await dispatch(subscribeToSSE(false));
		} catch (error) {
			if (!isRetry) {
				setTimeout(() => refresh(true), 5000);
			}
		}
	};

	if (refreshTime <= 0) {
		refresh();
		return;
	}
	tokenRefreshTimeout = setTimeout(refresh, refreshTime);
};

export const pollAirmeshChannels = () => async (dispatch, getState) => {
	if (pollingInterval) {
		clearInterval(pollingInterval);
	}

	const pollChannels = async () => {
		const previousChannels = airMeshChannelsSelector(getState());
		await dispatch(fetchAirMeshChannels());
		const latestChannels = airMeshChannelsSelector(getState());
		if (JSON.stringify(latestChannels) !== JSON.stringify(previousChannels)) {
			await dispatch(subscribeToSSE(false));
		}
	};

	pollingInterval = setInterval(pollChannels, 60000);
	await pollChannels(); // Initial poll
};

export const subscribeToSSE =
	(showLoader = true) =>
	async (dispatch, getState) => {
		if (showLoader) {
			dispatch(setAMConnectionLoading(true));
		}

		if (abortController) {
			abortController.abort();
		}

		abortController = new AbortController();

		try {
			const { token, url } = await dispatch(fetchAirMeshToken());
			const expiresAt = getJWTExpiration(token);

			// Schedule token refresh before establishing connection
			dispatch(scheduleTokenRefresh(expiresAt));

			const urlToUse = new URL(url);
			urlToUse.searchParams.append('topic', '*');

			await fetchEventSource(urlToUse.toString(), {
				method: 'GET',
				withCredentials: true,
				headers: {
					Authorization: `Bearer ${token}`,
					Accept: 'text/event-stream'
				},
				signal: abortController.signal,
				openWhenHidden: false,
				retry: false,

				onopen: async response => {
					if (!response.ok) {
						throw new Error(`Server returned ${response.status}`);
					}
					dispatch(setAMWebSocketConnected(true));
					dispatch(setAMConnectionError(false));
					dispatch(setAMConnectionLoading(false));
					if (!pollingInterval) {
						dispatch(pollAirmeshChannels());
					}
				},

				onmessage: event => {
					try {
						const data = JSON.parse(event.data);
						dispatch(processData(data.data));
					} catch (error) {
						console.error('Failed to parse SSE message:', error);
					}
				},

				onclose: () => {
					if (abortController.signal.aborted) return;
					dispatch(setAMWebSocketConnected(false));
				},

				onerror: err => {
					console.error('SSE connection error:', err);
					dispatch(setAMConnectionError(true));
					dispatch(setAMWebSocketConnected(false));
					dispatch(setAMConnectionLoading(false));
					throw err;
				}
			});
		} catch (error) {
			dispatch(setAMConnectionError(true));
			dispatch(setAMConnectionLoading(false));
			console.error('Error establishing SSE connection:', error);
		}
	};

export const stopAirmeshChannelPolling = () => {
	if (pollingInterval) {
		clearInterval(pollingInterval);
		pollingInterval = null;
	}
};

export const unsubscribeFromSSE = () => async (dispatch, getState) => {
	if (abortController) {
		abortController.abort();
		abortController = null;
	}
	stopAirmeshChannelPolling();
	if (tokenRefreshTimeout) {
		clearTimeout(tokenRefreshTimeout);
		tokenRefreshTimeout = null;
	}
	dispatch(setAMWebSocketConnected(false));
	dispatch(setAMConnectionError(false));
	dispatch(setAMConnectionLoading(false));
	dispatch(resetAMState());
};
