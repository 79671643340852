import Pusher from 'pusher-js';
import {
	resetAMState,
	setAMConnectionLoading,
	setAMConnectionError,
	setAMWebSocketConnected,
	setAMPusherInstance,
	airMeshPusherInstanceSelector,
	airMeshChannelsSelector
} from 'reducers/liveTrafficSlice';
import {
	AIRMESH_LIVE_TELEMETRY_PUSHER_APP_CLUSTER,
	AIRMESH_LIVE_TELEMETRY_PUSHER_APP_KEY,
	BASE_JWT_URL
} from 'constants/environmentVariables';
import { TOKEN } from 'constants/localStorageConstants';
import { processData } from 'utils/airMeshHelpers';
import { fetchAirMeshChannels } from 'apis/airspace.api';

let pollingInterval = null;

export const pollAirmeshChannels = () => async (dispatch, getState) => {
	if (pollingInterval) {
		clearInterval(pollingInterval);
	}

	const getAirMeshChannels = async () => {
		const previousChannels = airMeshChannelsSelector(getState());

		await dispatch(fetchAirMeshChannels());
		// Get channels from Redux after the fetch has completed and updated the store
		const latestChannels = airMeshChannelsSelector(getState());

		if (JSON.stringify(latestChannels) !== JSON.stringify(previousChannels)) {
			dispatch(handlePusherSubscriptions());
		}
	};
	pollingInterval = setInterval(getAirMeshChannels, 60000); // 60 seconds
};

export const stopAirmeshChannelPolling = () => {
	if (pollingInterval) {
		clearInterval(pollingInterval);
		pollingInterval = null;
	}
};

export const handleWebsocketClosed = () => dispatch => {
	dispatch(resetAMState());
};

export const handleWebsocketError = () => dispatch => {
	dispatch(setAMConnectionLoading(false));
	dispatch(setAMWebSocketConnected(false));
	dispatch(setAMConnectionError(true));
	console.error('Failed to connect to Air Mesh');
};

export const handlePusherConnect = () => async (dispatch, getState) => {
	await dispatch(fetchAirMeshChannels());
	const newPusherInstance = new Pusher(AIRMESH_LIVE_TELEMETRY_PUSHER_APP_KEY, {
		cluster: AIRMESH_LIVE_TELEMETRY_PUSHER_APP_CLUSTER,
		channelAuthorization: {
			endpoint: `${BASE_JWT_URL}/api/pusher-auth`,
			headers: {
				Authorization: 'Bearer ' + localStorage.getItem(TOKEN)
			}
		}
	});

	dispatch(setAMPusherInstance(newPusherInstance));

	newPusherInstance.connection.bind('connected', () => {
		dispatch(setAMWebSocketConnected(true));
		dispatch(setAMConnectionError(false));
		dispatch(setAMConnectionLoading(false));
		dispatch(handlePusherSubscriptions());
		dispatch(pollAirmeshChannels());
	});

	newPusherInstance.connection.bind('failed', e => {
		dispatch(handleWebsocketError());
	});

	newPusherInstance.connection.bind('unavailable', () => {
		dispatch(handleWebsocketError());
	});

	newPusherInstance.connection.bind('disconnected', () => {
		dispatch(handleWebsocketClosed());
	});
};

export const handlePusherSubscriptions = () => (dispatch, getState) => {
	const airMeshPusherInstance = airMeshPusherInstanceSelector(getState());
	const airMeshChannels = airMeshChannelsSelector(getState());
	const pusherKnownChannels = airMeshPusherInstance?.channels?.channels
		? Object.values(airMeshPusherInstance?.channels?.channels)
		: [];
	const pusherSubscribedChannels = pusherKnownChannels.filter(c => c.subscribed);

	const addedChannelNames = airMeshChannels.filter(
		channel => !pusherSubscribedChannels.map(c => c.name).includes(channel)
	);
	const removedChannels = pusherSubscribedChannels.filter(
		channel => !airMeshChannels.includes(channel.name)
	);

	addedChannelNames.forEach(channelName => {
		const newChannel = airMeshPusherInstance.subscribe(channelName);
		//Listen for track-event data
		newChannel.bind('track-event', event => {
			dispatch(processData(event.data));
		});

		//Listen for events to handle errors
		newChannel.bind('pusher:subscription_error', e => {
			dispatch(handleWebsocketError());
		});
	});

	removedChannels.forEach(channel => {
		channel.unsubscribe();
	});
};

export const handlePusherDisconnect = () => (dispatch, getState) => {
	const airMeshPusherInstance = airMeshPusherInstanceSelector(getState());
	if (airMeshPusherInstance) {
		airMeshPusherInstance.disconnect();
		stopAirmeshChannelPolling();
	}
};
