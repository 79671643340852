import { t } from 'i18next';
import { browserTimeZone, unixToStringWithTimeZone } from 'utils/timeHelpers';
import { startCase } from 'lodash';
import { $elevated, $success } from 'constants/styles';
import RectangleChip from 'components/elements/RectangleChip';
import { getOperatingPictureStatusFromValue } from 'components/airSpace/airspaceUtils';
import { meterToFt } from 'utils/conversionHelpers';

export const getLocationSearchOption = () => ({
	type: 'location',
	label: t('Location'),
	inputs: {}
});

export const lowAltitudeThreshold = 0;

export const getMidAltitudeThresholdFt = units => {
	if (units === 'metric') {
		//still calculate threshold in ft because altitude is in ft,
		// but for display purposes on the altitude legend the threshold should be the equivalent to 300m
		return { label: 300, value: meterToFt(300) };
	} else {
		//1000ft
		return { label: 1000, value: 1000 };
	}
};

export const getHighAltitudeThresholdFt = units => {
	if (units === 'metric') {
		//still calculate threshold in ft because altitude is in ft,
		// but for display purposes on the altitude legend the threshold should be the equivalent to 1500m
		return { label: 1500, value: meterToFt(1500) };
	} else {
		//5000ft
		return { label: 5000, value: 5000 };
	}
};

export const getOperatingPictureColumns = isForPreviewProp => {
	const getKey = key => {
		return isForPreviewProp ? key : `properties.${key}`;
	};

	return [
		{
			field: getKey('type'),
			headerName: t('Type'),
			width: 175,
			renderCell: params => {
				return startCase(params.row.properties.type);
			}
		},
		{
			field: getKey('is_own_account'),
			headerName: t('Account'),
			width: 125,
			renderCell: params => {
				return params.row.properties.is_own_account ? t('Current') : t('External');
			}
		},
		{
			field: getKey('status'),
			headerName: t('Status'),
			width: 200,
			renderCell: params => {
				const statusObj = getOperatingPictureStatusFromValue(params.row.properties.status);
				return <RectangleChip color={statusObj?.color} label={statusObj?.label} />;
			}
		},
		{
			field: getKey('facility'),
			headerName: t('Facility'),
			width: 100,
			renderCell: params => {
				return params.row.properties.facility;
			}
		},
		{
			field: getKey('ceiling'),
			headerName: t('Ceiling (ft)'),
			width: 100,
			renderCell: params => {
				return params.row.properties.ceiling;
			}
		},
		{
			field: getKey('starts'),
			headerName: t('Start'),
			width: 225,
			type: 'dateTimeWithZoneFromUnix',
			renderCell: params => {
				return unixToStringWithTimeZone(params.row.properties.starts, browserTimeZone);
			}
		},
		{
			headerName: t('End'),
			field: getKey('ends'),
			width: 225,
			type: 'dateTimeWithZoneFromUnix',
			renderCell: params => {
				return unixToStringWithTimeZone(params.row.properties.ends, browserTimeZone);
			}
		},
		{
			field: getKey('operators'),
			headerName: t('Operator'),
			width: 500,
			renderCell: params => {
				return params.row.properties.operators;
			}
		}
	];
};

export const getOperatingPictureTypes = () => [
	{
		value: 'mission',
		label: t('Mission')
	},
	{
		value: 'laanc',
		label: t('LAANC')
	},
	{
		value: 'flight_notification',
		label: t('Flight Notification')
	}
];

export const getOperatingPictureStatusOptions = () => [
	{
		value: 'ACTIVE',
		label: t('Active'),
		color: $success
	},
	{
		value: 'APPROVED',
		label: t('Approved'),
		color: $success
	},
	{
		value: 'PENDING',
		label: t('Pending'),
		color: $elevated
	}
];

export const getOperatingPictureSourceOptions = () => [
	{
		value: true,
		label: t('Current')
	},
	{
		value: false,
		label: t('External')
	}
];
